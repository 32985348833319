
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import { Fragment, useState } from 'react'
import dynamic from 'next/dynamic'
import useTranslation from 'next-translate/useTranslation'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'

import Meta from 'components/Meta'
import Loading from 'components/Loading'

import emailLogin from 'core/emailLogin'
import env from 'lib/env'

const DesktopLoginComponent = dynamic(
  import('components/pages/Auth/DesktopLogin'),
  {
    loading: Loading
  }
)

function DesktopLogin(props) {
  return <DesktopLoginComponent {...props} />
}

const MobileLoginComponent = dynamic(
  import('components/pages/Auth/MobileLogin'),
  {
    loading: Loading
  }
)

function MobileLogin(props) {
  return <MobileLoginComponent {...props} />
}

function Login() {
  const { t } = useTranslation('common')
  const router = useRouter()
  const { SCREEN_WIDTH } = env
  const dispatch = useDispatch()
  const appState = useSelector(state => state.appState)
  const {
    screenDimensions = {
      width: 0,
      height: 0
    }
  } = useSelector(state => state.details)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [checkEmailError, setCheckEmailError] = useState(false)
  const [checkPasswordError, setCheckPasswordError] = useState(false)
  const [checkLogin, setCheckLogin] = useState(false)

  const [error, setError] = useState('')

  const onEmailChange = e => {
    setError('')
    const testRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(e)
    if (e && !testRegex) {
      setCheckEmailError(true)
    } else {
      setCheckEmailError(false)
    }
    setEmail(e)
  }

  const onPasswordChange = e => {
    if (e && e.length > 64) return
    setError('')
    setCheckPasswordError(false)
    setPassword(e)
  }
  const handleLogin = async () => {
    dispatch({
      type: 'LOADING_BUTTON',
      payload: 'login'
    })
    setCheckLogin(true)
    let err = false
    if (email.length <= 0) {
      setCheckEmailError(true)
      err = true
      setCheckLogin(false)
      dispatch({
        type: 'LOADING_BUTTON',
        payload: null
      })
    }
    if (password.length < 8 || password.length > 64) {
      setCheckPasswordError(true)
      err = true
      setCheckLogin(false)
      dispatch({
        type: 'LOADING_BUTTON',
        payload: null
      })
    }

    if (!err) {
      await emailLogin({
        email,
        password,
        login: true,
        router,
        appState
      }).then(res => {
        if (res && res.message) {
          setError(`${t('ERRORS.wrong-email-or-pass')}`)
        }
        setCheckLogin(false)
        dispatch({
          type: 'LOADING_BUTTON',
          payload: null
        })
      })
    }
  }
  return (
    <Fragment>
      <Meta title='Trally | Login' />

      {screenDimensions.width > SCREEN_WIDTH ? (
        <DesktopLogin
          t={t}
          email={email}
          checkEmailError={checkEmailError}
          onEmailChange={onEmailChange}
          password={password}
          checkPasswordError={checkPasswordError}
          onPasswordChange={onPasswordChange}
          router={router}
          checkLogin={checkLogin}
          handleLogin={handleLogin}
          error={error}
          screenWidth={screenDimensions.width}
        />
      ) : (
        <MobileLogin
          t={t}
          email={email}
          checkEmailError={checkEmailError}
          onEmailChange={onEmailChange}
          password={password}
          checkPasswordError={checkPasswordError}
          onPasswordChange={onPasswordChange}
          router={router}
          checkLogin={checkLogin}
          handleLogin={handleLogin}
          error={error}
        />
      )}
    </Fragment>
  )
}

 async function _getServerSideProps({ req, resolvedUrl }) {
  const metaTags = {
    'og:title': 'Trally | Login',
    'og:description': 'Explore Trally!',
    'og:image':
      'https://trally-static-data.s3.ap-southeast-1.amazonaws.com/trally-default-preview.png',
    'og:url': `${req.headers.host}${resolvedUrl}`
  }

  return {
    props: {
      metaTags
    }
  }
}

export default Login


    export async function getServerSideProps(ctx) {
        let res = _getServerSideProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/login/index',
              loaderName: 'getServerSideProps',
              ...__i18nConfig,
              
            }))
          }
        }
    }
  