import Head from 'next/head'

function Meta({ title, description, imageUrl }) {
  return (
    <Head>
      <title>{title}</title>
      <meta
        name='description'
        content={description ? description : 'Explore Trally!'}
      />

      <meta property='og:title' content={title} />
      <meta property='og:type' content='website' />
      <meta property='og:url' content={window.location.href} />
      {imageUrl && <meta property='og:image' content={imageUrl} />}
      <link rel='icon' href='/favicon.svg' />
    </Head>
  )
}

export default Meta
